import type { Asset } from "types"

/**
 * Get Asset Path for legacy S3 assets
 *
 * @param {Object} asset - Asset to parse
 * @param {String} size - Size of asset to return. Based on slug in S3.
 * @returns
 */
export const getLegacyAssetPath = (asset: Asset, size = "large") => {
    const cdnPath =
        process.env.cdnDomain ||
        process.env.NEXT_PUBLIC_CDN_DOMAIN ||
        "https://d3szackftvnutw.cloudfront.net"

    if (asset?.path) {
        const path = asset?.path.indexOf("/") !== 0 ? `/${asset.path}` : asset.path
        const suffix = path.search(/\.[a-zA-Z0-9]{2,4}/) ? "" : `-${size}.png`

        return `${cdnPath}${path}${suffix}`
    } else return false
}
