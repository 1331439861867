export const getAllBeerStyles = /* GraphQL */ `
    query GetStyles($input: inputGetAll) {
	getStyles(input: $input){
        items {
            id
            title
			# primaryFlavorNotes
        }
        nextToken
    }
}
`
