export const servingTempsTerms = {
    Very_Cold: "0-4°C / 32-39°F",
    Cold: "4-7°C / 39-45°F",
    Cool: "8-12°C / 45-54°F",
    Cellar: "12-14°C / 54-57°F",
    Warm: "14-16°C / 57-61°F",
    Hot: "70°C / 158°F"
}
export const srmTerms = {
    1: "Light Straw",
    2: "Straw",
    3: "Straw",
    4: "Straw",
    5: "Light Gold",
    6: "Gold",
    7: "Gold",
    8: "Gold",
    9: "Gold",
    10: "Light Amber",
    11: "Light Amber",
    12: "Amber",
    13: "Amber",
    14: "Amber",
    15: "Amber",
    16: "Dark Amber",
    17: "Dark Amber",
    18: "Dark Amber",
    19: "Light Brown",
    20: "Brown",
    21: "Brown",
    22: "Brown",
    23: "Brown",
    24: "Brown",
    25: "Brown",
    26: "Brown",
    27: "Brown",
    28: "Brown",
    29: "Brown",
    30: "Dark Brown",
    31: "Dark Brown",
    32: "Dark Brown",
    33: "Dark Brown",
    34: "Dark Brown",
    35: "Dark Brown",
    36: "Dark Brown",
    37: "Dark Brown",
    38: "Black",
    39: "Black",
    40: "Black",
    41: "Black",
    42: "Black",
    43: "Black",
    44: "Black",
    45: "Black",
    46: "Black",
    47: "Black",
    48: "Black",
    49: "Black",
    50: "Black",
    51: "Black",
    52: "Black",
    53: "Black",
    54: "Black",
    55: "Black",
    56: "Black",
    57: "Black",
    58: "Black",
    59: "Black",
    60: "Black",
    61: "Black",
    62: "Black",
    63: "Black",
    64: "Black",
    65: "Black",
    66: "Black",
    67: "Black",
    68: "Black",
    69: "Black",
    70: "Black",
    71: "Black",
    72: "Black",
    73: "Black",
    74: "Black",
    75: "Black",
    76: "Black",
    77: "Black",
    78: "Black",
    79: "Black",
    80: "Black",
    81: "Black",
    82: "Black",
    83: "Black",
    84: "Black",
    85: "Black",
    86: "Black",
    87: "Black",
    88: "Black",
    89: "Black",
    90: "Black",
    91: "Black",
    92: "Black",
    93: "Black",
    94: "Black",
    95: "Black",
    96: "Black",
    97: "Black",
    98: "Black",
    99: "Black",
    100: "Black"
}

export const statesUS = {
    AL: {
        label: "Alabama",
        code: "AL"
    },
    AK: {
        label: "Alaska",

        code: "AK"
    },
    AZ: {
        label: "Arizona",
        code: "AZ"
    },
    AR: {
        label: "Arkansas",
        code: "AR"
    },
    CA: {
        label: "California",

        code: "CA"
    },
    CO: {
        label: "Colorado",
        code: "CO"
    },
    CT: {
        label: "Connecticut",
        code: "CT"
    },
    DE: {
        label: "Delaware",
        code: "DE"
    },
    DC: {
        label: "District of Columbia",
        code: "DC"
    },
    FL: {
        label: "Florida",
        code: "FL"
    },
    GA: {
        label: "Georgia",
        code: "GA"
    },
    HI: {
        label: "Hawaii",

        code: "HI"
    },
    ID: {
        label: "Idaho",
        code: "ID"
    },
    IL: {
        label: "Illinois",
        code: "IL"
    },
    IN: {
        label: "Indiana",
        code: "IN"
    },
    IA: {
        label: "Iowa",
        code: "IA"
    },
    KS: {
        label: "Kansas",
        code: "KS"
    },
    KY: {
        label: "Kentucky",
        code: "KY"
    },
    LA: {
        label: "Louisiana",
        code: "LA"
    },
    ME: {
        label: "Maine",
        code: "ME"
    },
    MD: {
        label: "Maryland",
        code: "MD"
    },
    MA: {
        label: "Massachusetts",
        code: "MA"
    },
    MI: {
        label: "Michigan",
        code: "MI"
    },
    MN: {
        label: "Minnesota",
        code: "MN"
    },
    MS: {
        label: "Mississippi",
        code: "MS"
    },
    MO: {
        label: "Missouri",
        code: "MO"
    },
    MT: {
        label: "Montana",
        code: "MT"
    },
    NE: {
        label: "Nebraska",
        code: "NE"
    },
    NV: {
        label: "Nevada",
        code: "NV"
    },
    NH: {
        label: "New Hampshire",
        code: "NH"
    },
    NJ: {
        label: "New Jersey",
        code: "NJ"
    },
    NM: {
        label: "New Mexico",
        code: "NM"
    },
    NY: {
        label: "New York",
        code: "NY"
    },
    NC: {
        label: "North Carolina",
        Abbrev: "N.C.",
        code: "NC"
    },
    ND: {
        label: "North Dakota",
        code: "ND"
    },
    OH: {
        label: "Ohio",
        code: "OH"
    },
    OK: {
        label: "Oklahoma",
        code: "OK"
    },
    OR: {
        label: "Oregon",
        code: "OR"
    },
    PA: {
        label: "Pennsylvania",
        code: "PA"
    },
    RI: {
        label: "Rhode Island",
        code: "RI"
    },
    SC: {
        label: "South Carolina",
        code: "SC"
    },
    SD: {
        label: "South Dakota",
        code: "SD"
    },
    TN: {
        label: "Tennessee",
        code: "TN"
    },
    TX: {
        label: "Texas",
        code: "TX"
    },
    UT: {
        label: "Utah",
        code: "UT"
    },
    VT: {
        label: "Vermont",
        code: "VT"
    },
    VA: {
        label: "Virginia",
        code: "VA"
    },
    WA: {
        label: "Washington",
        code: "WA"
    },
    WV: {
        label: "West Virginia",
        code: "WV"
    },
    WI: {
        label: "Wisconsin",
        code: "WI"
    },
    WY: {
        label: "Wyoming",
        code: "WY"
    }
}
export const daysOfWeek = [
    {
        label: "Sun",
        name: "Sunday"
    },
    {
        label: "Mon",
        name: "Sunday"
    },
    {
        label: "Tue",
        name: "Sunday"
    },
    {
        label: "Wed",
        name: "Sunday"
    },
    {
        label: "Thu",
        name: "Sunday"
    },
    {
        label: "Fri",
        name: "Sunday"
    },
    {
        label: "Sat",
        name: "Sunday"
    }
]
