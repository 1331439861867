import type { CompositeProduct } from "types"
import { srmTerms } from "./terms"

interface ProductColor {
    label?: string
    value: string
}

export const calculateFillColor = (item: CompositeProduct): string => {
    let color = "gray-300"

    if (item?.color) return item?.color

    if (item?.srm) return `srm-${item?.srm}`

    if (item?.style && item?.style?.srmMin) {
        color = "srm-" + Math.floor((item.style?.srmMin + item.style?.srmMax) / 2)
    }

    return color
}

export const deKebab = (str: string): string => {
    if (!str) return ""

    return str.replace(/(-)/g, " ").toLowerCase()
}

export const parseColor = (product: CompositeProduct) => {
    const value = "bg-" + calculateFillColor(product)
    let label = "--"
    if (product?.__typename === "Beer") {
        label = srmTerms[value.replace("bg-srm-", "")]
    } else {
        label =
            product?.color === "flower-fruity-fruity"
                ? "Fruity"
                : deKebab(product?.color?.replace("flower-", "") || value)
    }

    const productColor: ProductColor = {
        label,
        value
    }

    return productColor
}
