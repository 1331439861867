import React from "react"
interface IOutsideClick {
    ref: React.Ref<unknown>
    handler: (event: MouseEvent) => void
}

type Handler = (event: MouseEvent) => void

function useOutsideClick<T extends HTMLElement = HTMLElement>(
    ref: React.RefObject<T>,
    handler: Handler
) {
    React.useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref?.current && !ref.current?.contains(event.target as Node)) {
                if (handler) {
                    handler(event)
                }
            }
        }
        window.addEventListener("mousedown", handleClickOutside)
        return () => {
            window.removeEventListener("mousedown", handleClickOutside)
        }
    }, [ref, handler])
}

export default useOutsideClick
