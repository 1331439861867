import { type OperationDefinitionNode, getOperationAST, parse } from "graphql"
import { capitalizeWord } from "../../string"

interface IParseGraphQlOperation {
    query: string
}
interface GraphQlOperation {
    name: string
    type: string
    key: string
}

const parseGraphQlOperation = ({ query }: IParseGraphQlOperation): GraphQlOperation => {
    try {
        const operationNode: OperationDefinitionNode = getOperationAST(parse(query))
        return {
            name: operationNode?.name?.value,
            type: operationNode?.operation,
            key: [
                capitalizeWord(operationNode?.operation),
                capitalizeWord(operationNode?.name?.value)
            ].join(".")
        }
    } catch (error) {
        console.log(error)
    }
}

export default parseGraphQlOperation
