import { type Address, type PublicApi, Utility } from "types"

/**
 * Parse and format an {@link Address} object into string
 *
 * @typedef {function} parseAddress
 * @param {(Address | PublicApi.Address)} address - {@link Address} to format
 * @param {?Utility.Address.AddressFormat} [format] - Format of returned string, defaults to `Street, City, State`
 * @return {string}  Formatted address string. e.g. `123 Fake St., Someville, CA`
 */
const parseAddress = (
    address: Address | PublicApi.Address,
    format: Utility.Address.AddressFormat = Utility.Address.AddressFormat.StreetCityState
): string => {
    if (!address) return ""
    const stateCodeIndex: number = Object.values(Utility.Address.USStates).findIndex(
        (item: string) => item === address?.state
    )
    const stateCode: string =
        stateCodeIndex !== -1
            ? Object.keys(Utility.Address.USStates)[stateCodeIndex]
            : address?.state
    const state: string = stateCode || address?.state
    if (format === Utility.Address.AddressFormat.CityState) return `${address?.city}, ${state}`
    if (format === Utility.Address.AddressFormat.Complete)
        return Object.values({ ...address, state }).join(",")
    if (format === Utility.Address.AddressFormat.Street)
        return `${address?.street}, ${address?.street2}`

    return `${address?.street}, ${address?.city}, ${state}`
}

export default parseAddress
