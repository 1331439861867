import { DateTime } from "luxon"
import type { Keg } from "types"

export const getDaysOnTap = (keg: Keg): number | null => {
    if (!keg?.actualKickDate || !keg?.tappedDate) return null

    const actualKickDate = DateTime.fromISO(keg.actualKickDate)
    const tappedDate = DateTime.fromISO(keg.tappedDate)

    return Math.floor(actualKickDate.diff(tappedDate, "days").days)
}
