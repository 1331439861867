export const tapSensorFragment = /* GraphQL */ `{
        id
        barTitle
        tapNumber
        sensorDeviceId
        transformedReading
        lastValidRawReading
        rawReading
        tappedKeg {
            id
            cooler
            coupler
            cost
            kegStatus
            bornOnDate
            expirationDate
            depletionStats {
                depletionPace
                depletionRate
                projectedKickDate
                depletionEstimates {
                    date
                    fluidOz
                }
            }
            updatedDate
            servings {
                pourSize
                isDefault
            }
            defaultServingSize
            vessel {
                slug
                iconSlug
                label
                shortTitle
            }
            product {
                __typename
                id
                title
                abv
                style {
                    kegFreshnessDuration
                }
                assets {
                    __typename
                    id
                    alt
                    type
                    caption
                    ... on S3Asset {
                        path
                    }
                    ... on CloudinaryAsset {
                        cloudinaryId
                    }
                }
                color
                description
                primaryFlavorNotes
                hintsOfFlavorNotes
                traits {
                    nutrition {
                        calories
                        fat
                        saturatedFat
                        transFat
                        cholesterol
                        sodium
                        carbohydrates
                        dietaryFiber
                        sugars
                        protein
                    }
                    allergens
                    otherTraits
                    availability
                }
                servingTemperature
                servingVessels
                ingredients {
                    type
                    title
                }
                brand {
                    id
                    slug
                    title
                    displayTitle
                    address {
                        city
                        state
                    }
                    assets {
                        __typename
                        id
                        alt
                        type
                        caption
                        ... on S3Asset {
                            path
                        }
                        ... on CloudinaryAsset {
                            cloudinaryId
                        }
                    }
                }
                tastingExperience
                ... on Beer {
                    ibu
                    srm
                    perceivedBitterness
                    style {
                        abvMax
                        abvMin
                        createdDate
                        description
                        fgMax
                        fgMin
                        servingVessels
                        hintsOfFlavorNotes
                        hops
                        ibuMax
                        ibuMin
                        id
                        label
                        malt
                        ogMax
                        ogMin
                        primaryFlavorNotes
                        servingTemperature
                        slug
                        srmMax
                        srmMin
                        title
                        updatedDate
                        yeast
                    }
                    
                }
                ... on Kombucha {
                    appearance
                    acidity
                    body
                    brixCount
                    carbonation
                    kombuchaFamily
                    perceivedSweetness
                    probiotics
                    sourness
                    teaCharacter
                }
                ... on Cider {
                    id
                    slug
                    title
                    acidity
                    body
                    carbonation
                    ciderFamily
                    perceivedSweetness
                    tannin
                }
            }
            initialFluidOz
            currentFluidOz
            isTemporary
            cost
            createdDate
            id
            receivedDate
            isPartial
            isEstimate
            tappedDate
            servingSizes
            defaultServingSize
        }
        assignedKegs {
            bornOnDate
            expirationDate
            actualKickDate
            cooler
            coupler
            currentFluidOz
            id
            initialFluidOz
            kegStatus
            isTemporary
            receivedDate
            lastUpdatedBy
            assignedPositionIndex
            product {
                __typename
                id
                title
                color
                style {
                    kegFreshnessDuration
                }
                assets {
                    __typename
                    id
                    alt
                    type
                    caption
                    ... on S3Asset {
                        path
                    }
                    ... on CloudinaryAsset {
                        cloudinaryId
                    }
                }
                brand {
                    id
                    title
                    displayTitle
                    assets {
                        __typename
                        id
                        alt
                        type
                        caption
                        ... on S3Asset {
                            path
                        }
                        ... on CloudinaryAsset {
                            cloudinaryId
                        }
                    }
                }
                ... on UnverifiedProduct {
                    brandName
                }
                ... on Beer {
                    id
                    abv
                    ibu
                    srm
                    style {
                        title
                        id
                        abvMax
                        abvMin
                        fgMax
                        fgMin
                        ibuMax
                        ibuMin
                        hops
                        malt
                        ogMax
                        ogMin
                        srmMax
                        srmMin
                        yeast
                    }
                    title
                }
                ... on Kombucha {                  
                    appearance
                    acidity
                    body
                    brixCount
                    carbonation
                    kombuchaFamily
                    perceivedSweetness
                    probiotics
                    sourness
                    teaCharacter
                }
                ... on Cider {
                    id
                    slug
                    title
                    acidity
                    body
                    carbonation
                    ciderFamily
                    perceivedSweetness
                    tannin
                }
            }
            vessel {
                title
                slug
                iconSlug
                shortTitle
                material
                slug
                tareWeight
            }
            depletionStats {
                depletionPace
                depletionRate
                projectedKickDate
                depletionEstimates {
                    date
                    fluidOz
                }
            }
            cost
            tappedDate
            updatedDate
        }
    }
`
