"use client"
import { useEffect, useState } from "react"

// Pass it CSS media query
const useMediaQuery = (query: string): boolean => {
    const [matches, setMatches] = useState(false)

    useEffect(() => {
        const mediaQueryList = window.matchMedia(query)
        if (mediaQueryList.matches !== matches) {
            setMatches(mediaQueryList.matches)
        }
        const listener = () => setMatches(mediaQueryList.matches)
        window.addEventListener("resize", listener)
    }, [query, matches])

    return matches
}

export default useMediaQuery
