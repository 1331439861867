import { tailwindConfig } from "../../../tailwind-config/tailwind.config"

export const COLORS = [tailwindConfig.theme.colors.gray["100"], "#CBF17A", "#8BC115", "#49650B"]
export const COLORSMapping = {
    "#E0E0E0": "gray-100",
    "#CBF17A": "green-200",
    "#8BC115": "green-400",
    "#49650B": "green-600"
}

export const data01 = [
    {
        hour: "2am",
        index: 1,
        value: 100,
        fill: tailwindConfig.theme.colors.gray["100"],
        day: ""
    },
    {
        hour: "3am",
        index: 1,
        value: 100,
        fill: tailwindConfig.theme.colors.gray["100"],
        day: ""
    },
    {
        hour: "4am",
        index: 1,
        value: 100,
        fill: tailwindConfig.theme.colors.gray["100"],
        day: ""
    },
    {
        hour: "5am",
        index: 1,
        value: 100,
        fill: tailwindConfig.theme.colors.gray["100"],
        day: ""
    },
    {
        hour: "6am",
        index: 1,
        value: 100,
        fill: tailwindConfig.theme.colors.gray["100"],
        day: ""
    },
    {
        hour: "7am",
        index: 1,
        value: 100,
        fill: tailwindConfig.theme.colors.gray["100"],
        day: ""
    },
    {
        hour: "8am",
        index: 1,
        value: 100,
        fill: tailwindConfig.theme.colors.gray["100"],
        day: ""
    },
    {
        hour: "9am",
        index: 1,
        value: 100,
        fill: tailwindConfig.theme.colors.green["200"],
        day: ""
    },
    {
        hour: "10am",
        index: 1,
        value: 100,
        fill: tailwindConfig.theme.colors.green["200"],
        day: ""
    },
    {
        hour: "11am",
        index: 1,
        value: 150,
        fill: tailwindConfig.theme.colors.green["200"],
        day: ""
    },
    {
        hour: "12pm",
        index: 1,
        value: 160,
        fill: tailwindConfig.theme.colors.green["200"],
        day: ""
    },
    {
        hour: "1pm",
        index: 1,
        value: 170,
        fill: tailwindConfig.theme.colors.green["400"],
        day: ""
    },
    {
        hour: "2pm",
        index: 1,
        value: 180,
        fill: tailwindConfig.theme.colors.green["400"],
        day: ""
    },
    {
        hour: "3pm",
        index: 1,
        value: 144,
        fill: tailwindConfig.theme.colors.green["400"],
        day: ""
    },
    {
        hour: "4pm",
        index: 1,
        value: 166,
        fill: tailwindConfig.theme.colors.green["400"],
        day: ""
    },
    {
        hour: "5pm",
        index: 1,
        value: 250,
        fill: tailwindConfig.theme.colors.green["400"],
        day: ""
    },
    {
        hour: "6pm",
        index: 1,
        value: 300,
        fill: tailwindConfig.theme.colors.green["400"],
        day: ""
    },
    {
        hour: "7pm",
        index: 1,
        value: 300,
        fill: tailwindConfig.theme.colors.green["600"],
        day: ""
    },
    {
        hour: "8pm",
        index: 1,
        value: 300,
        fill: tailwindConfig.theme.colors.green["600"],
        day: ""
    },
    {
        hour: "9pm",
        index: 1,
        value: 300,
        fill: tailwindConfig.theme.colors.green["600"],
        day: ""
    },
    {
        hour: "10pm",
        index: 1,
        value: 300,
        fill: tailwindConfig.theme.colors.green["600"],
        day: ""
    },
    {
        hour: "11pm",
        index: 1,
        value: 300,
        fill: tailwindConfig.theme.colors.green["600"],
        day: ""
    },
    {
        hour: "12am",
        index: 1,
        value: 300,
        fill: tailwindConfig.theme.colors.green["600"],
        day: ""
    },
    {
        hour: "1am",
        index: 1,
        value: 300,
        fill: tailwindConfig.theme.colors.green["600"],
        day: ""
    }
]

export const data03 = [
    {
        hour: "2am",
        index: 1,
        value: 400,
        fill: tailwindConfig.theme.colors.gray["100"]
    },
    {
        hour: "",
        index: 1,
        value: 400,
        fill: tailwindConfig.theme.colors.gray["100"]
    },
    {
        hour: "4am",
        index: 1,
        value: 400,
        fill: tailwindConfig.theme.colors.gray["100"]
    },
    {
        hour: "",
        index: 1,
        value: 400,
        fill: tailwindConfig.theme.colors.gray["100"]
    },
    {
        hour: "6am",
        index: 1,
        value: 400,
        fill: tailwindConfig.theme.colors.gray["100"]
    },
    {
        hour: "",
        index: 1,
        value: 400,
        fill: tailwindConfig.theme.colors.gray["100"]
    },
    {
        hour: "8am",
        index: 1,
        value: 400,
        fill: tailwindConfig.theme.colors.gray["100"]
    },
    {
        hour: "",
        index: 1,
        value: 400,
        fill: tailwindConfig.theme.colors.gray["100"]
    },
    {
        hour: "10am",
        index: 1,
        value: 100,
        fill: tailwindConfig.theme.colors.gray["100"]
    },
    {
        hour: "",
        index: 1,
        value: 150,
        fill: tailwindConfig.theme.colors.gray["100"]
    },
    {
        hour: "12pm",
        index: 1,
        value: 160,
        fill: tailwindConfig.theme.colors.gray["100"]
    },
    {
        hour: "",
        index: 1,
        value: 170,
        fill: tailwindConfig.theme.colors.gray["100"]
    },
    {
        hour: "2pm",
        index: 1,
        value: 180,
        fill: tailwindConfig.theme.colors.gray["100"]
    },
    {
        hour: "",
        index: 1,
        value: 144,
        fill: tailwindConfig.theme.colors.gray["100"]
    },
    {
        hour: "4pm",
        index: 1,
        value: 166,
        fill: tailwindConfig.theme.colors.gray["100"]
    },
    {
        hour: "",
        index: 1,
        value: 250,
        fill: tailwindConfig.theme.colors.gray["100"]
    },
    {
        hour: "6pm",
        index: 1,
        value: 300,
        fill: tailwindConfig.theme.colors.gray["100"]
    },
    {
        hour: "",
        index: 1,
        value: 300,
        fill: tailwindConfig.theme.colors.gray["100"]
    },
    {
        hour: "8pm",
        index: 1,
        value: 300,
        fill: tailwindConfig.theme.colors.gray["100"]
    },
    {
        hour: "",
        index: 1,
        value: 300,
        fill: tailwindConfig.theme.colors.gray["100"]
    },
    {
        hour: "10pm",
        index: 1,
        value: 300,
        fill: tailwindConfig.theme.colors.gray["100"]
    },
    {
        hour: "",
        index: 1,
        value: 300,
        fill: tailwindConfig.theme.colors.gray["100"]
    },
    {
        hour: "12am",
        index: 1,
        value: 300,
        fill: tailwindConfig.theme.colors.gray["100"]
    },
    {
        hour: "",
        index: 1,
        value: 300,
        fill: tailwindConfig.theme.colors.gray["100"]
    }
]

export const DAYS = ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"]
export const DAYSMapping = {
    Sun: 7,
    Mon: 7,
    Tue: 7 + 1,
    Wed: 7 + 2,
    Thu: 7 + 3,
    Fri: 7 + 4,
    Sat: 7
}

export const TimeMapping = {
    US___Eastern: "America/New_York",
    US___Central: "America/Chicago",
    US___Mountain: "America/Denver",
    US___Pacific: "America/Los_Angeles",
    US___Alaska: "America/Anchorage",
    US___Hawaii: "Pacific/Honolulu",
    US___Aleutian: "Pacific/Honolulu",
    US___Arizona: "America/Denver",
    US___East__Indiana: "America/New_York",
    US___Indiana__Starke: "America/Chicago",
    US___Michigan: "America/New_York"
}

export const HOURS = [
    "2am",
    "3am",
    "4am",
    "5am",
    "6am",
    "7am",
    "8am",
    "9am",
    "10am",
    "11am",
    "12pm",
    "1pm",
    "2pm",
    "3pm",
    "4pm",
    "5pm",
    "6pm",
    "7pm",
    "8pm",
    "9pm",
    "10pm",
    "11pm",
    "12am",
    "1am"
]

export const DepletionHistoryDefaultData = [
    {
        date: "2022-09-07T23:58:02.747-07:00",
        totalPercentRemaining: 54,
        totalThroughput: 4,
        tappedDate: "2022-08-26T16:49:35.066-07:00",
        type: "depletionHistory",
        labelDate: "09/07",
        depletionEstimates: null
    },
    {
        date: "2022-09-08T23:59:03.607-07:00",
        totalPercentRemaining: 50,
        totalThroughput: 68,
        tappedDate: "2022-08-26T16:49:35.066-07:00",
        type: "depletionHistory",
        labelDate: "09/08",
        depletionEstimates: null
    },
    {
        date: "2022-09-09T23:47:04.858-07:00",
        totalPercentRemaining: 44,
        totalThroughput: 98,
        tappedDate: "2022-08-26T16:49:35.066-07:00",
        type: "depletionHistory",
        labelDate: "09/09",
        depletionEstimates: null
    },
    {
        date: "2022-09-10T23:39:23.625-07:00",
        totalPercentRemaining: 36,
        totalThroughput: 144,
        tappedDate: "2022-08-26T16:49:35.066-07:00",
        type: "depletionHistory",
        labelDate: "09/10",
        depletionEstimates: null
    },
    {
        date: "2022-09-11T01:38:40.485-07:00",
        totalPercentRemaining: 36,
        totalThroughput: 0,
        tappedDate: "2022-08-26T16:49:35.066-07:00",
        type: "depletionHistory",
        labelDate: "09/11",
        depletionEstimates: 36
    },
    {
        date: "2022-09-13T14:20:54.339-07:00",
        type: "depletionHistory",
        labelDate: "09/13",
        depletionEstimates: 25
    },
    {
        date: "2022-09-14T14:20:54.339-07:00",
        type: "depletionHistory",
        labelDate: "09/14",
        depletionEstimates: 20
    }
]

export const fullWeekOfDefaultData = {
    Sun: [
        {
            hour: "12 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sun",
            date: ""
        },
        {
            hour: "1 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sun",
            date: ""
        },
        {
            hour: "2 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sun",
            date: ""
        },
        {
            hour: "3 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sun",
            date: ""
        },
        {
            hour: "4 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sun",
            date: ""
        },
        {
            hour: "5 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sun",
            date: ""
        },
        {
            hour: "6 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sun",
            date: ""
        },
        {
            hour: "7 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sun",
            date: ""
        },
        {
            hour: "8 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sun",
            date: ""
        },
        {
            hour: "9 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sun",
            date: ""
        },
        {
            hour: "10 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sun",
            date: ""
        },
        {
            hour: "11 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sun",
            date: ""
        },
        {
            hour: "12 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sun",
            date: ""
        },
        {
            hour: "1 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sun",
            date: ""
        },
        {
            hour: "2 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sun",
            date: ""
        },
        {
            hour: "3 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sun",
            date: ""
        },
        {
            hour: "4 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sun",
            date: ""
        },
        {
            hour: "5 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sun",
            date: ""
        },
        {
            hour: "6 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sun",
            date: ""
        },
        {
            hour: "7 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sun",
            date: ""
        },
        {
            hour: "8 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sun",
            date: ""
        },
        {
            hour: "9 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sun",
            date: ""
        },
        {
            hour: "10 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sun",
            date: ""
        },
        {
            hour: "11 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sun",
            date: ""
        }
    ],
    Mon: [
        {
            hour: "12 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Mon",
            date: ""
        },
        {
            hour: "1 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Mon",
            date: ""
        },
        {
            hour: "2 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Mon",
            date: ""
        },
        {
            hour: "3 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Mon",
            date: ""
        },
        {
            hour: "4 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Mon",
            date: ""
        },
        {
            hour: "5 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Mon",
            date: ""
        },
        {
            hour: "6 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Mon",
            date: ""
        },
        {
            hour: "7 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Mon",
            date: ""
        },
        {
            hour: "8 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Mon",
            date: ""
        },
        {
            hour: "9 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Mon",
            date: ""
        },
        {
            hour: "10 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Mon",
            date: ""
        },
        {
            hour: "11 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Mon",
            date: ""
        },
        {
            hour: "12 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Mon",
            date: ""
        },
        {
            hour: "1 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Mon",
            date: ""
        },
        {
            hour: "2 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Mon",
            date: ""
        },
        {
            hour: "3 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Mon",
            date: ""
        },
        {
            hour: "4 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Mon",
            date: ""
        },
        {
            hour: "5 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Mon",
            date: ""
        },
        {
            hour: "6 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Mon",
            date: ""
        },
        {
            hour: "7 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Mon",
            date: ""
        },
        {
            hour: "8 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Mon",
            date: ""
        },
        {
            hour: "9 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Mon",
            date: ""
        },
        {
            hour: "10 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Mon",
            date: ""
        },
        {
            hour: "11 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Mon",
            date: ""
        }
    ],
    Tue: [
        {
            hour: "12 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Tue",
            date: ""
        },
        {
            hour: "1 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Tue",
            date: ""
        },
        {
            hour: "2 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Tue",
            date: ""
        },
        {
            hour: "3 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Tue",
            date: ""
        },
        {
            hour: "4 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Tue",
            date: ""
        },
        {
            hour: "5 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Tue",
            date: ""
        },
        {
            hour: "6 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Tue",
            date: ""
        },
        {
            hour: "7 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Tue",
            date: ""
        },
        {
            hour: "8 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Tue",
            date: ""
        },
        {
            hour: "9 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Tue",
            date: ""
        },
        {
            hour: "10 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Tue",
            date: ""
        },
        {
            hour: "11 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Tue",
            date: ""
        },
        {
            hour: "12 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Tue",
            date: ""
        },
        {
            hour: "1 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Tue",
            date: ""
        },
        {
            hour: "2 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Tue",
            date: ""
        },
        {
            hour: "3 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Tue",
            date: ""
        },
        {
            hour: "4 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Tue",
            date: ""
        },
        {
            hour: "5 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Tue",
            date: ""
        },
        {
            hour: "6 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Tue",
            date: ""
        },
        {
            hour: "7 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Tue",
            date: ""
        },
        {
            hour: "8 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Tue",
            date: ""
        },
        {
            hour: "9 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Tue",
            date: ""
        },
        {
            hour: "10 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Tue",
            date: ""
        },
        {
            hour: "11 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Tue",
            date: ""
        }
    ],
    Wed: [
        {
            hour: "12 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Wed",
            date: ""
        },
        {
            hour: "1 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Wed",
            date: ""
        },
        {
            hour: "2 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Wed",
            date: ""
        },
        {
            hour: "3 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Wed",
            date: ""
        },
        {
            hour: "4 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Wed",
            date: ""
        },
        {
            hour: "5 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Wed",
            date: ""
        },
        {
            hour: "6 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Wed",
            date: ""
        },
        {
            hour: "7 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Wed",
            date: ""
        },
        {
            hour: "8 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Wed",
            date: ""
        },
        {
            hour: "9 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Wed",
            date: ""
        },
        {
            hour: "10 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Wed",
            date: ""
        },
        {
            hour: "11 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Wed",
            date: ""
        },
        {
            hour: "12 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Wed",
            date: ""
        },
        {
            hour: "1 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Wed",
            date: ""
        },
        {
            hour: "2 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Wed",
            date: ""
        },
        {
            hour: "3 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Wed",
            date: ""
        },
        {
            hour: "4 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Wed",
            date: ""
        },
        {
            hour: "5 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Wed",
            date: ""
        },
        {
            hour: "6 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Wed",
            date: ""
        },
        {
            hour: "7 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Wed",
            date: ""
        },
        {
            hour: "8 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Wed",
            date: ""
        },
        {
            hour: "9 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Wed",
            date: ""
        },
        {
            hour: "10 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Wed",
            date: ""
        },
        {
            hour: "11 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Wed",
            date: ""
        }
    ],
    Thu: [
        {
            hour: "12 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Thu",
            date: ""
        },
        {
            hour: "1 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Thu",
            date: ""
        },
        {
            hour: "2 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Thu",
            date: ""
        },
        {
            hour: "3 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Thu",
            date: ""
        },
        {
            hour: "4 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Thu",
            date: ""
        },
        {
            hour: "5 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Thu",
            date: ""
        },
        {
            hour: "6 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Thu",
            date: ""
        },
        {
            hour: "7 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Thu",
            date: ""
        },
        {
            hour: "8 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Thu",
            date: ""
        },
        {
            hour: "9 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Thu",
            date: ""
        },
        {
            hour: "10 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Thu",
            date: ""
        },
        {
            hour: "11 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Thu",
            date: ""
        },
        {
            hour: "12 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Thu",
            date: ""
        },
        {
            hour: "1 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Thu",
            date: ""
        },
        {
            hour: "2 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Thu",
            date: ""
        },
        {
            hour: "3 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Thu",
            date: ""
        },
        {
            hour: "4 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Thu",
            date: ""
        },
        {
            hour: "5 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Thu",
            date: ""
        },
        {
            hour: "6 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Thu",
            date: ""
        },
        {
            hour: "7 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Thu",
            date: ""
        },
        {
            hour: "8 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Thu",
            date: ""
        },
        {
            hour: "9 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Thu",
            date: ""
        },
        {
            hour: "10 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Thu",
            date: ""
        },
        {
            hour: "11 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Thu",
            date: ""
        }
    ],
    Fri: [
        {
            hour: "12 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Fri",
            date: ""
        },
        {
            hour: "1 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Fri",
            date: ""
        },
        {
            hour: "2 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Fri",
            date: ""
        },
        {
            hour: "3 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Fri",
            date: ""
        },
        {
            hour: "4 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Fri",
            date: ""
        },
        {
            hour: "5 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Fri",
            date: ""
        },
        {
            hour: "6 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Fri",
            date: ""
        },
        {
            hour: "7 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Fri",
            date: ""
        },
        {
            hour: "8 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Fri",
            date: ""
        },
        {
            hour: "9 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Fri",
            date: ""
        },
        {
            hour: "10 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Fri",
            date: ""
        },
        {
            hour: "11 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Fri",
            date: ""
        },
        {
            hour: "12 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Fri",
            date: ""
        },
        {
            hour: "1 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Fri",
            date: ""
        },
        {
            hour: "2 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Fri",
            date: ""
        },
        {
            hour: "3 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Fri",
            date: ""
        },
        {
            hour: "4 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Fri",
            date: ""
        },
        {
            hour: "5 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Fri",
            date: ""
        },
        {
            hour: "6 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Fri",
            date: ""
        },
        {
            hour: "7 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Fri",
            date: ""
        },
        {
            hour: "8 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Fri",
            date: ""
        },
        {
            hour: "9 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Fri",
            date: ""
        },
        {
            hour: "10 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Fri",
            date: ""
        },
        {
            hour: "11 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Fri",
            date: ""
        }
    ],
    Sat: [
        {
            hour: "12 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sat",
            date: ""
        },
        {
            hour: "1 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sat",
            date: ""
        },
        {
            hour: "2 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sat",
            date: ""
        },
        {
            hour: "3 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sat",
            date: ""
        },
        {
            hour: "4 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sat",
            date: ""
        },
        {
            hour: "5 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sat",
            date: ""
        },
        {
            hour: "6 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sat",
            date: ""
        },
        {
            hour: "7 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sat",
            date: ""
        },
        {
            hour: "8 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sat",
            date: ""
        },
        {
            hour: "9 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sat",
            date: ""
        },
        {
            hour: "10 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sat",
            date: ""
        },
        {
            hour: "11 am",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sat",
            date: ""
        },
        {
            hour: "12 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sat",
            date: ""
        },
        {
            hour: "1 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sat",
            date: ""
        },
        {
            hour: "2 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sat",
            date: ""
        },
        {
            hour: "3 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sat",
            date: ""
        },
        {
            hour: "4 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sat",
            date: ""
        },
        {
            hour: "5 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sat",
            date: ""
        },
        {
            hour: "6 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sat",
            date: ""
        },
        {
            hour: "7 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sat",
            date: ""
        },
        {
            hour: "8 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sat",
            date: ""
        },
        {
            hour: "9 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sat",
            date: ""
        },
        {
            hour: "10 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sat",
            date: ""
        },
        {
            hour: "11 pm",
            index: 1,
            value: 0,
            fill: tailwindConfig.theme.colors.gray["100"],
            day: "Sat",
            date: ""
        }
    ]
}
