"use client"
import { useEffect } from "react"
import type { TableColumn } from "../../../../../apps/pint-labs/src/components/tables/types"
import { usePersistentStore } from "../usePersistentStore"
import { useTableConfigStore } from "./useTableConfigStore"

export const useColumnConfig = <T, U>(
    tableKey: string,
    config: TableColumn<T, U>[],
    defaultColumns: T[] = []
) => {
    const store = usePersistentStore(useTableConfigStore, (state) => state)
    useEffect(() => {
        if (!store) return
        const selectedTable = store.tables[tableKey]
        if (!selectedTable && defaultColumns.length > 0) {
            store.setTableColumns(tableKey, defaultColumns as string[])
        }
    }, [defaultColumns, store?.tables, store?.setTableColumns])

    const handleToggleColumn = (column: T) => store.toggleColumn(tableKey, column as string)
    const setColumns = (columns: T[]) => store.setTableColumns(tableKey, columns as string[])

    const selectedColumns = store?.tables[tableKey]?.columns || (defaultColumns as string[])
    const columns = config.filter((c) => selectedColumns.includes(c.type as string))

    return {
        columns,
        toggleColumn: handleToggleColumn,
        setColumns
    }
}
