import { type CompositeProduct, ProductFields, SortDirection } from "types"

export const sortProductsByFields = (
    data: CompositeProduct[],
    dir: SortDirection,
    field: ProductFields
) => {
    const arr = data
    switch (field) {
        case ProductFields.Title:
            arr.sort((a, b) => {
                return dir === SortDirection.Desc
                    ? (a?.title?.trim() || "")?.localeCompare(b?.title?.trim() || "")
                    : (b?.title?.trim() || "")?.localeCompare(a?.title?.trim() || "")
            })
            break
        case ProductFields.Id:
            arr.sort((a, b) => {
                return dir === SortDirection.Asc
                    ? a.id?.localeCompare(b.id)
                    : b.id?.localeCompare(a.id)
            })
            break
        case ProductFields.Type:
            arr.sort((a, b) => {
                return dir === SortDirection.Asc
                    ? a.__typename?.localeCompare(b.__typename)
                    : b.__typename?.localeCompare(a.__typename)
            })
            break
        case ProductFields.Brand: {
            const title = (p: CompositeProduct) => p?.brand?.title.trim()
            arr.sort((a, b) =>
                dir === SortDirection.Asc
                    ? title(a)?.localeCompare(title(b))
                    : title(b)?.localeCompare(title(a))
            )
            break
        }
        case ProductFields.Updated: {
            arr.sort((a: CompositeProduct, b: CompositeProduct) => {
                const aTime = new Date(a.updatedDate).getTime().toString()
                const bTime = new Date(b.updatedDate).getTime().toString()
                return dir === SortDirection.Asc
                    ? bTime?.localeCompare(aTime, "en", {
                          numeric: true
                      })
                    : aTime?.localeCompare(bTime, "en", {
                          numeric: true
                      })
            })
            break
        }
        case ProductFields.Created: {
            arr.sort((a: CompositeProduct, b: CompositeProduct) => {
                const aTime = new Date(a.createdDate).getTime().toString()
                const bTime = new Date(b.createdDate).getTime().toString()
                return dir === SortDirection.Asc
                    ? bTime?.localeCompare(aTime, "en", {
                          numeric: true
                      })
                    : aTime?.localeCompare(bTime, "en", {
                          numeric: true
                      })
            })
            break
        }
        case ProductFields.Availability:
            arr.sort((a, b) => {
                return dir === SortDirection.Asc
                    ? a?.traits?.availability?.localeCompare(b?.traits?.availability)
                    : b?.traits?.availability?.localeCompare(a?.traits?.availability)
            })
            break
        case ProductFields.NewProduct:
            arr.sort((a, b) => {
                const aIsNew = !!a.isNewProduct
                const bIsNew = !!b.isNewProduct
                return dir === SortDirection.Asc
                    ? aIsNew.toString().localeCompare(bIsNew.toString())
                    : bIsNew.toString().localeCompare(aIsNew.toString())
            })
            break
        case ProductFields.Style:
            arr.sort((a, b) => {
                return dir === SortDirection.Asc
                    ? a?.style?.title?.localeCompare(b?.style?.title)
                    : b?.style?.title?.localeCompare(a?.style?.title)
            })
            break
        case ProductFields.BlxGroup:
            arr.sort((a, b) => {
                return dir === SortDirection.Asc
                    ? a?.style?.blxGroup?.localeCompare(b?.style?.blxGroup)
                    : b?.style?.blxGroup?.localeCompare(a?.style?.blxGroup)
            })
            break
        case ProductFields.Locations:
            arr.sort((a, b) => {
                return dir === SortDirection.Asc
                    ? a?.locationsAvailable?.length - b?.locationsAvailable?.length
                    : b?.locationsAvailable?.length - a?.locationsAvailable?.length
            })
            break
        case ProductFields.ABV:
            arr.sort((a, b) => {
                const aAbv = a?.abv ?? -1
                const bAbv = b?.abv ?? -1
                return dir === SortDirection.Asc ? aAbv - bAbv : bAbv - aAbv
            })
            break
        case ProductFields.IBU:
            arr.sort((a, b) => {
                const aIbu = a?.ibu ?? 0
                const bIbu = b?.ibu ?? 0
                return dir === SortDirection.Asc ? aIbu - bIbu : bIbu - aIbu
            })
            break
        case ProductFields.SRM:
            arr.sort((a, b) => {
                const aSrm = a?.srm ?? 0
                const bSrm = b?.srm ?? 0
                return dir === SortDirection.Asc ? aSrm - bSrm : bSrm - aSrm
            })
            break
        case ProductFields.Color:
            arr.sort((a, b) => {
                const aColor = a?.color ?? ""
                const bColor = b?.color ?? ""
                return dir === SortDirection.Asc
                    ? aColor.localeCompare(bColor)
                    : bColor.localeCompare(aColor)
            })
            break
        case ProductFields.Slug:
            arr.sort((a, b) => {
                return dir === SortDirection.Asc
                    ? a.slug.localeCompare(b.slug)
                    : b.slug.localeCompare(a.slug)
            })
            break
        default:
            break
    }
    return arr
}
