import { DisplayColumns } from "types"

const getColumnNumber = (displayColumn: DisplayColumns) => {
    const mapping = {
        [DisplayColumns.One]: 1,
        [DisplayColumns.Two]: 2,
        [DisplayColumns.Three]: 3,
        [DisplayColumns.Four]: 4
    }
    return mapping[displayColumn]
}

export default getColumnNumber
