import React from "react"

const useKeyPress = (
    key: string,
    callback: (event: KeyboardEvent) => void,
    meta = false,
    node = null
) => {
    const callbackRef = React.useRef(callback)
    React.useLayoutEffect(() => {
        callbackRef.current = callback
    })

    const handleKeyPress = React.useCallback(
        (event: KeyboardEvent) => {
            if (meta) {
                if (event.metaKey && event.key === key) {
                    callbackRef.current(event)
                }
            } else {
                if (event.key === key) {
                    callbackRef.current(event)
                }
            }
        },
        [key]
    )

    React.useEffect(() => {
        const targetNode = node ?? document
        targetNode && targetNode.addEventListener("keydown", handleKeyPress)

        return () => targetNode && targetNode.removeEventListener("keydown", handleKeyPress)
    }, [handleKeyPress, node])
}

export default useKeyPress
