import type { CompositeProduct } from "types"
import { parseEnumLabel } from "../string"

const parseProductStyle = (product: CompositeProduct): string => {
    const style =
        product?.style?.label ||
        product?.style?.title ||
        product?.kombuchaFamily ||
        product?.ciderFamily ||
        product?.__typename ||
        ""

    return parseEnumLabel(style)
}

export default parseProductStyle
