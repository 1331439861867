import { tailwindConfig } from "../../../tailwind-config/tailwind.config"
import { propertyByString } from "../string"
/**
 * parseLiquidColorSlug
 *
 * Maps color slug to hex value
 *
 *
 * @param {string} slug
 * @return { object  } - class name and hex color
 */
export const parseLiquidColorSlug = (slug = "") => {
    const colorOptions = tailwindConfig.theme.colors
    let fillColor
    let hexColor = null
    if (slug.includes("-")) {
        fillColor = slug.split("-")
        if (fillColor[0] === "srm") {
            fillColor[1] = Number.parseInt(fillColor[1]) > 40 ? 40 : fillColor[1]
        }
        hexColor = propertyByString(colorOptions, fillColor.join("."))
    } else {
        hexColor = colorOptions[slug]
    }
    return hexColor
}
