export const srmColorMap = {
    "bg-srm-1": "#F8F4B4",
    "bg-srm-2": "#F9E06C",
    "bg-srm-3": "#F4CE51",
    "bg-srm-4": "#F2BE37",
    "bg-srm-5": "#EDAC1E",
    "bg-srm-6": "#E59C19",
    "bg-srm-7": "#DF8F16",
    "bg-srm-8": "#D68019",
    "bg-srm-9": "#CF731C",
    "bg-srm-10": "#BD591B",
    "bg-srm-11": "#C3621B",
    "bg-srm-12": "#C86B1B",
    "bg-srm-13": "#C05727",
    "bg-srm-14": "#AD4417",
    "bg-srm-15": "#AE4818",
    "bg-srm-16": "#AD4417",
    "bg-srm-17": "#A73D15",
    "bg-srm-18": "#A23A15",
    "bg-srm-19": "#9D3414",
    "bg-srm-20": "#983015",
    "bg-srm-21": "#932A14",
    "bg-srm-22": "#8D2615",
    "bg-srm-23": "#892515",
    "bg-srm-24": "#832212",
    "bg-srm-25": "#7D200F",
    "bg-srm-26": "#771E0E",
    "bg-srm-27": "#731C0B",
    "bg-srm-28": "#70180C",
    "bg-srm-29": "#6A160C",
    "bg-srm-30": "#67120B",
    "bg-srm-31": "#63100A",
    "bg-srm-32": "#5F0E0A",
    "bg-srm-33": "#5B0B0A",
    "bg-srm-34": "#58080B",
    "bg-srm-35": "#4B090B",
    "bg-srm-36": "#4B090B",
    "bg-srm-37": "#470D0C",
    "bg-srm-38": "#400C0E",
    "bg-srm-39": "#3C0B0E",
    "bg-srm-40": "#240A0B",
    "bg-flower-fruity-fuity": "#FF0000",
    "bg-flower-fruity-berry": "#990000",
    "bg-flower-fruity-apple": "#CC0100",
    "bg-flower-fruity-stonefruit": "#E06666",
    "bg-flower-fruity-tropical": "#EA9999",
    "bg-flower-fruity-melon": "#EA9999",
    "bg-flower-fruity-citrus": "#F4CCCC",
    "bg-flower-floral": "#FF9902",
    "bg-flower-herbaceous": "#FFFF04",
    "bg-flower-grassy": "#92C47C",
    "bg-flower-vegetal": "#284E13",
    "bg-flower-earthy": "#7F6001",
    "bg-flower-woody": "#BF9001",
    "bg-flower-spicy": "#B45F05",
    "bg-flower-sweetaromatic": "#741C47"
}
