import { statesUS } from "./constants"

/**
 * parseStateLabel()
 *
 * @param {string} name - State label or 2 letter code
 * @returns {string} - 2 letter state code, if it exists in the UsStates term map
 */
export const parseStateLabel = (name = null) => {
    if (!name) return ""
    if (name && name.length === 2) {
        return name
    } else {
        const stateLabel = Object.values(statesUS)
            .filter((item) => {
                return item.label === name
            })
            .map((item) => {
                return item.code
            })
        return stateLabel && stateLabel.length ? stateLabel : name
    }
}
