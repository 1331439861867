import { KegStatus } from "types"
import { ozLeft } from "../ozLeft/ozLeft"

export const isLowKeg = (
    transformedReading: number | undefined,
    currentFlOz: number | undefined,
    kegStatus: KegStatus
): boolean => {
    if (kegStatus === KegStatus.Tapped && !transformedReading) return false
    return ozLeft(kegStatus === KegStatus.Tapped ? transformedReading : undefined, currentFlOz) < 64
}
