import { tailwindConfig } from "../../../tailwind-config/tailwind.config"

export const getProductColorOptions = () => {
    const colors = Object.keys(tailwindConfig.theme.colors).flatMap((color) =>
        Object.keys(tailwindConfig.theme.colors[color]).map((shade) => `${color}-${shade}`)
    )
    return colors.map((option) => ({
        label: option,
        value: option
    }))
}
