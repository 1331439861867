import { BrewTypes } from "types"
import type { IOption } from "types/src/utility/ui"
import { parseEnumLabel } from "../string"
import supportedBrewTypes from "./brewTypes"

type BrewTypeOption = IOption<string, BrewTypes>

const brewTypeOptions = (all: boolean = false): BrewTypeOption[] => {
    const brewTypes = Object.keys(BrewTypes)
        .map((key) => ({
            label: parseEnumLabel(BrewTypes?.[key]),
            value: String(key),
            data: BrewTypes?.[key]
        }))
        .filter((option: BrewTypeOption) => all || supportedBrewTypes.includes(option?.data))

    return brewTypes
}

export default brewTypeOptions
