/**
 * An enum representing the different BLX applications.
 *
 * - `Operations`: The Perforamnce Platform. Used to manage business operations, such as inventory, orders, and customers.
 * - `Marketing`: The Market My Brewery.  Used to create and manage marketing campaigns.
 * - `BrewMenu`: BrewMenu Administration.
 * - `BrewKnowledge`: BrewKnowledge from Perforamnce Platform.
 */
export enum BLXApplications {
    Operations = "performancePlatform",
    Marketing = "marketMyBrewery",
    BrewMenu = "brewMenu",
    BrewKnowledge = "brewKnowledge"
}
