import { DateTime } from 'luxon';

interface DateTimeObject {
    dayOfWeek: string
    date: string
    time: string
}

interface ToDateInput {
    isoString: string
    use24HourFormat?: boolean
}

/**
 * Converts an ISO date string to a `DateTimeObject` containing formatted day of the week, date, and time strings.
 *
 * @param {ToDateInput} params - The input parameters.
 * @param {string} params.isoString - The ISO date string to be converted.
 * @param {boolean} [params.use24HourFormat=false] - Optional flag to determine if the time should be in 24-hour format.
 *
 * @returns {DateTimeObject} An object containing the formatted day of the week, date, and time strings.
 */
export function toDate({ isoString, use24HourFormat = false }: ToDateInput): DateTimeObject {
    const dt = DateTime.fromISO(isoString)
    const result: DateTimeObject = {
        dayOfWeek: dt.toFormat("EEE"), // Short day of the week
        date: dt.toFormat("MMM dd, yyyy"), // Date in "MMM dd, yyyy" format
        time: use24HourFormat ? dt.toFormat("HH:mm:ss") : dt.toFormat("hh:mm:ss a")
    };
    return result;
}
