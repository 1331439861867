export const pageLimit = 100
export const maxPageLimit = 1000

// Enums
export const BREW_OPTION = {
    Beer: "Beer",
    Kombucha: "Kombucha",
    Seltzer: "Seltzer",
    Cider: "Cider",
    Non__Alcoholic: "Non__Alcoholic",
    Coffee: "Coffee",
    Wine: "Wine",
    Gluten_Free: "Gluten_Free",
    Cocktails: "Cocktails"
}

export const OTHER_PRODUCT_TRAITS = {
    Certified_Organic: "Certified_Organic",
    Gluten_Free: "Gluten_Free",
    Gluten_Reduced: "Gluten_Reduced",
    Non__Alcoholic: "Non__Alcoholic",
    Dairy_Free: "Dairy_Free"
}

export const FOOD_OPTION = {
    Carry_In: "Carry_In",
    Farm_to_Table: "Farm_to_Table",
    Food_Truck: "Food_Truck",
    Free_Range: "Free_Range",
    Full_Service_Menu: "Full_Service_Menu",
    Gluten_Free: "Gluten_Free",
    Late_Night_Menu: "Late_Night_Menu",
    Limited_Menu: "Limited_Menu",
    Order__in: "Order__in",
    Organic: "Organic",
    Snacks_and_Apps: "Snacks_and_Apps",
    Vegan: "Vegan",
    Vegetarian: "Vegetarian"
}

export const SEATING_OPTION = {
    Waterfront: "Waterfront",
    Beer_Garden: "Beer_Garden",
    Call_Ahead: "Call_Ahead",
    Indoor: "Indoor",
    Mountain_Side: "Mountain_Side",
    Outdoor: "Outdoor",
    Patio: "Patio",
    Reservations: "Reservations",
    Rooftop: "Rooftop"
}

export const PATRON_OPTION = {
    All_Pets: "All_Pets",
    Dogs: "Dogs",
    Family_Friendly: "Family_Friendly",
    WiFi_Available: "WiFi_Available"
}

export const statesUS = {
    AL: {
        label: "Alabama",
        code: "AL"
    },
    AK: {
        label: "Alaska",

        code: "AK"
    },
    AZ: {
        label: "Arizona",
        code: "AZ"
    },
    AR: {
        label: "Arkansas",
        code: "AR"
    },
    CA: {
        label: "California",

        code: "CA"
    },
    CO: {
        label: "Colorado",
        code: "CO"
    },
    CT: {
        label: "Connecticut",
        code: "CT"
    },
    DE: {
        label: "Delaware",
        code: "DE"
    },
    DC: {
        label: "District of Columbia",
        code: "DC"
    },
    FL: {
        label: "Florida",
        code: "FL"
    },
    GA: {
        label: "Georgia",
        code: "GA"
    },
    HI: {
        label: "Hawaii",

        code: "HI"
    },
    ID: {
        label: "Idaho",
        code: "ID"
    },
    IL: {
        label: "Illinois",
        code: "IL"
    },
    IN: {
        label: "Indiana",
        code: "IN"
    },
    IA: {
        label: "Iowa",
        code: "IA"
    },
    KS: {
        label: "Kansas",
        code: "KS"
    },
    KY: {
        label: "Kentucky",
        code: "KY"
    },
    LA: {
        label: "Louisiana",
        code: "LA"
    },
    ME: {
        label: "Maine",
        code: "ME"
    },
    MD: {
        label: "Maryland",
        code: "MD"
    },
    MA: {
        label: "Massachusetts",
        code: "MA"
    },
    MI: {
        label: "Michigan",
        code: "MI"
    },
    MN: {
        label: "Minnesota",
        code: "MN"
    },
    MS: {
        label: "Mississippi",
        code: "MS"
    },
    MO: {
        label: "Missouri",
        code: "MO"
    },
    MT: {
        label: "Montana",
        code: "MT"
    },
    NE: {
        label: "Nebraska",
        code: "NE"
    },
    NV: {
        label: "Nevada",
        code: "NV"
    },
    NH: {
        label: "New Hampshire",
        code: "NH"
    },
    NJ: {
        label: "New Jersey",
        code: "NJ"
    },
    NM: {
        label: "New Mexico",
        code: "NM"
    },
    NY: {
        label: "New York",
        code: "NY"
    },
    NC: {
        label: "North Carolina",
        Abbrev: "N.C.",
        code: "NC"
    },
    ND: {
        label: "North Dakota",
        code: "ND"
    },
    OH: {
        label: "Ohio",
        code: "OH"
    },
    OK: {
        label: "Oklahoma",
        code: "OK"
    },
    OR: {
        label: "Oregon",
        code: "OR"
    },
    PA: {
        label: "Pennsylvania",
        code: "PA"
    },
    RI: {
        label: "Rhode Island",
        code: "RI"
    },
    SC: {
        label: "South Carolina",
        code: "SC"
    },
    SD: {
        label: "South Dakota",
        code: "SD"
    },
    TN: {
        label: "Tennessee",
        code: "TN"
    },
    TX: {
        label: "Texas",
        code: "TX"
    },
    UT: {
        label: "Utah",
        code: "UT"
    },
    VT: {
        label: "Vermont",
        code: "VT"
    },
    VA: {
        label: "Virginia",
        code: "VA"
    },
    WA: {
        label: "Washington",
        code: "WA"
    },
    WV: {
        label: "West Virginia",
        code: "WV"
    },
    WI: {
        label: "Wisconsin",
        code: "WI"
    },
    WY: {
        label: "Wyoming",
        code: "WY"
    }
}

export const countries: { name: string; code: string }[] = [
    { name: "Afghanistan", code: "AF" },
    { name: "Åland Islands", code: "AX" },
    { name: "Albania", code: "AL" },
    { name: "Algeria", code: "DZ" },
    { name: "American Samoa", code: "AS" },
    { name: "AndorrA", code: "AD" },
    { name: "Angola", code: "AO" },
    { name: "Anguilla", code: "AI" },
    { name: "Antarctica", code: "AQ" },
    { name: "Antigua and Barbuda", code: "AG" },
    { name: "Argentina", code: "AR" },
    { name: "Armenia", code: "AM" },
    { name: "Aruba", code: "AW" },
    { name: "Australia", code: "AU" },
    { name: "Austria", code: "AT" },
    { name: "Azerbaijan", code: "AZ" },
    { name: "Bahamas", code: "BS" },
    { name: "Bahrain", code: "BH" },
    { name: "Bangladesh", code: "BD" },
    { name: "Barbados", code: "BB" },
    { name: "Belarus", code: "BY" },
    { name: "Belgium", code: "BE" },
    { name: "Belize", code: "BZ" },
    { name: "Benin", code: "BJ" },
    { name: "Bermuda", code: "BM" },
    { name: "Bhutan", code: "BT" },
    { name: "Bolivia", code: "BO" },
    { name: "Bosnia and Herzegovina", code: "BA" },
    { name: "Botswana", code: "BW" },
    { name: "Bouvet Island", code: "BV" },
    { name: "Brazil", code: "BR" },
    { name: "British Indian Ocean Territory", code: "IO" },
    { name: "Brunei Darussalam", code: "BN" },
    { name: "Bulgaria", code: "BG" },
    { name: "Burkina Faso", code: "BF" },
    { name: "Burundi", code: "BI" },
    { name: "Cambodia", code: "KH" },
    { name: "Cameroon", code: "CM" },
    { name: "Canada", code: "CA" },
    { name: "Cape Verde", code: "CV" },
    { name: "Cayman Islands", code: "KY" },
    { name: "Central African Republic", code: "CF" },
    { name: "Chad", code: "TD" },
    { name: "Chile", code: "CL" },
    { name: "China", code: "CN" },
    { name: "Christmas Island", code: "CX" },
    { name: "Cocos (Keeling) Islands", code: "CC" },
    { name: "Colombia", code: "CO" },
    { name: "Comoros", code: "KM" },
    { name: "Congo", code: "CG" },
    { name: "Congo, The Democratic Republic of the", code: "CD" },
    { name: "Cook Islands", code: "CK" },
    { name: "Costa Rica", code: "CR" },
    { name: "Cote D'Ivoire", code: "CI" },
    { name: "Croatia", code: "HR" },
    { name: "Cuba", code: "CU" },
    { name: "Cyprus", code: "CY" },
    { name: "Czech Republic", code: "CZ" },
    { name: "Denmark", code: "DK" },
    { name: "Djibouti", code: "DJ" },
    { name: "Dominica", code: "DM" },
    { name: "Dominican Republic", code: "DO" },
    { name: "Ecuador", code: "EC" },
    { name: "Egypt", code: "EG" },
    { name: "El Salvador", code: "SV" },
    { name: "Equatorial Guinea", code: "GQ" },
    { name: "Eritrea", code: "ER" },
    { name: "Estonia", code: "EE" },
    { name: "Ethiopia", code: "ET" },
    { name: "Falkland Islands (Malvinas)", code: "FK" },
    { name: "Faroe Islands", code: "FO" },
    { name: "Fiji", code: "FJ" },
    { name: "Finland", code: "FI" },
    { name: "France", code: "FR" },
    { name: "French Guiana", code: "GF" },
    { name: "French Polynesia", code: "PF" },
    { name: "French Southern Territories", code: "TF" },
    { name: "Gabon", code: "GA" },
    { name: "Gambia", code: "GM" },
    { name: "Georgia", code: "GE" },
    { name: "Germany", code: "DE" },
    { name: "Ghana", code: "GH" },
    { name: "Gibraltar", code: "GI" },
    { name: "Greece", code: "GR" },
    { name: "Greenland", code: "GL" },
    { name: "Grenada", code: "GD" },
    { name: "Guadeloupe", code: "GP" },
    { name: "Guam", code: "GU" },
    { name: "Guatemala", code: "GT" },
    { name: "Guernsey", code: "GG" },
    { name: "Guinea", code: "GN" },
    { name: "Guinea-Bissau", code: "GW" },
    { name: "Guyana", code: "GY" },
    { name: "Haiti", code: "HT" },
    { name: "Heard Island and Mcdonald Islands", code: "HM" },
    { name: "Holy See (Vatican City State)", code: "VA" },
    { name: "Honduras", code: "HN" },
    { name: "Hong Kong", code: "HK" },
    { name: "Hungary", code: "HU" },
    { name: "Iceland", code: "IS" },
    { name: "India", code: "IN" },
    { name: "Indonesia", code: "ID" },
    { name: "Iran, Islamic Republic Of", code: "IR" },
    { name: "Iraq", code: "IQ" },
    { name: "Ireland", code: "IE" },
    { name: "Isle of Man", code: "IM" },
    { name: "Israel", code: "IL" },
    { name: "Italy", code: "IT" },
    { name: "Jamaica", code: "JM" },
    { name: "Japan", code: "JP" },
    { name: "Jersey", code: "JE" },
    { name: "Jordan", code: "JO" },
    { name: "Kazakhstan", code: "KZ" },
    { name: "Kenya", code: "KE" },
    { name: "Kiribati", code: "KI" },
    { name: "Korea, Democratic People'S Republic of", code: "KP" },
    { name: "Korea, Republic of", code: "KR" },
    { name: "Kuwait", code: "KW" },
    { name: "Kyrgyzstan", code: "KG" },
    { name: "Lao People'S Democratic Republic", code: "LA" },
    { name: "Latvia", code: "LV" },
    { name: "Lebanon", code: "LB" },
    { name: "Lesotho", code: "LS" },
    { name: "Liberia", code: "LR" },
    { name: "Libyan Arab Jamahiriya", code: "LY" },
    { name: "Liechtenstein", code: "LI" },
    { name: "Lithuania", code: "LT" },
    { name: "Luxembourg", code: "LU" },
    { name: "Macao", code: "MO" },
    { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
    { name: "Madagascar", code: "MG" },
    { name: "Malawi", code: "MW" },
    { name: "Malaysia", code: "MY" },
    { name: "Maldives", code: "MV" },
    { name: "Mali", code: "ML" },
    { name: "Malta", code: "MT" },
    { name: "Marshall Islands", code: "MH" },
    { name: "Martinique", code: "MQ" },
    { name: "Mauritania", code: "MR" },
    { name: "Mauritius", code: "MU" },
    { name: "Mayotte", code: "YT" },
    { name: "Mexico", code: "MX" },
    { name: "Micronesia, Federated States of", code: "FM" },
    { name: "Moldova, Republic of", code: "MD" },
    { name: "Monaco", code: "MC" },
    { name: "Mongolia", code: "MN" },
    { name: "Montserrat", code: "MS" },
    { name: "Morocco", code: "MA" },
    { name: "Mozambique", code: "MZ" },
    { name: "Myanmar", code: "MM" },
    { name: "Namibia", code: "NA" },
    { name: "Nauru", code: "NR" },
    { name: "Nepal", code: "NP" },
    { name: "Netherlands", code: "NL" },
    { name: "Netherlands Antilles", code: "AN" },
    { name: "New Caledonia", code: "NC" },
    { name: "New Zealand", code: "NZ" },
    { name: "Nicaragua", code: "NI" },
    { name: "Niger", code: "NE" },
    { name: "Nigeria", code: "NG" },
    { name: "Niue", code: "NU" },
    { name: "Norfolk Island", code: "NF" },
    { name: "Northern Mariana Islands", code: "MP" },
    { name: "Norway", code: "NO" },
    { name: "Oman", code: "OM" },
    { name: "Pakistan", code: "PK" },
    { name: "Palau", code: "PW" },
    { name: "Palestinian Territory, Occupied", code: "PS" },
    { name: "Panama", code: "PA" },
    { name: "Papua New Guinea", code: "PG" },
    { name: "Paraguay", code: "PY" },
    { name: "Peru", code: "PE" },
    { name: "Philippines", code: "PH" },
    { name: "Pitcairn", code: "PN" },
    { name: "Poland", code: "PL" },
    { name: "Portugal", code: "PT" },
    { name: "Puerto Rico", code: "PR" },
    { name: "Qatar", code: "QA" },
    { name: "Reunion", code: "RE" },
    { name: "Romania", code: "RO" },
    { name: "Russian Federation", code: "RU" },
    { name: "RWANDA", code: "RW" },
    { name: "Saint Helena", code: "SH" },
    { name: "Saint Kitts and Nevis", code: "KN" },
    { name: "Saint Lucia", code: "LC" },
    { name: "Saint Pierre and Miquelon", code: "PM" },
    { name: "Saint Vincent and the Grenadines", code: "VC" },
    { name: "Samoa", code: "WS" },
    { name: "San Marino", code: "SM" },
    { name: "Sao Tome and Principe", code: "ST" },
    { name: "Saudi Arabia", code: "SA" },
    { name: "Senegal", code: "SN" },
    { name: "Serbia and Montenegro", code: "CS" },
    { name: "Seychelles", code: "SC" },
    { name: "Sierra Leone", code: "SL" },
    { name: "Singapore", code: "SG" },
    { name: "Slovakia", code: "SK" },
    { name: "Slovenia", code: "SI" },
    { name: "Solomon Islands", code: "SB" },
    { name: "Somalia", code: "SO" },
    { name: "South Africa", code: "ZA" },
    { name: "South Georgia and the South Sandwich Islands", code: "GS" },
    { name: "Spain", code: "ES" },
    { name: "Sri Lanka", code: "LK" },
    { name: "Sudan", code: "SD" },
    { name: "Suriname", code: "SR" },
    { name: "Svalbard and Jan Mayen", code: "SJ" },
    { name: "Swaziland", code: "SZ" },
    { name: "Sweden", code: "SE" },
    { name: "Switzerland", code: "CH" },
    { name: "Syrian Arab Republic", code: "SY" },
    { name: "Taiwan, Province of China", code: "TW" },
    { name: "Tajikistan", code: "TJ" },
    { name: "Tanzania, United Republic of", code: "TZ" },
    { name: "Thailand", code: "TH" },
    { name: "Timor-Leste", code: "TL" },
    { name: "Togo", code: "TG" },
    { name: "Tokelau", code: "TK" },
    { name: "Tonga", code: "TO" },
    { name: "Trinidad and Tobago", code: "TT" },
    { name: "Tunisia", code: "TN" },
    { name: "Turkey", code: "TR" },
    { name: "Turkmenistan", code: "TM" },
    { name: "Turks and Caicos Islands", code: "TC" },
    { name: "Tuvalu", code: "TV" },
    { name: "Uganda", code: "UG" },
    { name: "Ukraine", code: "UA" },
    { name: "United Arab Emirates", code: "AE" },
    { name: "United Kingdom", code: "GB" },
    { name: "United States", code: "US" },
    { name: "United States Minor Outlying Islands", code: "UM" },
    { name: "Uruguay", code: "UY" },
    { name: "Uzbekistan", code: "UZ" },
    { name: "Vanuatu", code: "VU" },
    { name: "Venezuela", code: "VE" },
    { name: "Viet Nam", code: "VN" },
    { name: "Virgin Islands, British", code: "VG" },
    { name: "Virgin Islands, U.S.", code: "VI" },
    { name: "Wallis and Futuna", code: "WF" },
    { name: "Western Sahara", code: "EH" },
    { name: "Yemen", code: "YE" },
    { name: "Zambia", code: "ZM" },
    { name: "Zimbabwe", code: "ZW" }
]

export const countryOptions = countries.map((country) => ({
    label: country.name,
    value: country.code
}))

export const facilityTypeOptions = [
    {
        label: "Production Facility",
        value: "Production_Facility"
    },
    {
        label: "Tap Tasting Room",
        value: "Tap_Tasting_Room"
    },
    {
        label: "Brew Pub",
        value: "Brew_Pub"
    },
    {
        label: "Restaurant and Brewhouse",
        value: "Restaurant_and_Brewhouse"
    },
    {
        label: "Office",
        value: "Office"
    }
]

export const operationStatusOptions = [
    {
        label: "Open for Business",
        value: "Open_for_Business"
    },
    {
        label: "Coming Soon",
        value: "Coming_Soon"
    },
    {
        label: "Temporarily Closed",
        value: "Temporarily_Closed"
    },
    {
        label: "Closed",
        value: "Closed"
    },
    {
        label: "Office",
        value: "Office"
    }
]

export const ServingVesselOptions = [
    {
        label: "Arome",
        value: "Arome"
    },
    {
        label: "Boot",
        value: "Boot"
    },
    {
        label: "Bottle",
        value: "Bottle"
    },
    {
        label: "Can",
        value: "Can"
    },
    {
        label: "Can Glass",
        value: "Can_Glass"
    },
    {
        label: "Chalice",
        value: "Chalice"
    },
    {
        label: "Coffee Cup",
        value: "Coffee_Cup"
    },
    {
        label: "Craft Master",
        value: "Craft_Master"
    },
    {
        label: "Crowler",
        value: "Crowler"
    },
    {
        label: "Flared Pint",
        value: "Flared_Pint"
    },
    {
        label: "Flute",
        value: "Flute"
    },
    {
        label: "Glencairn Whiskey",
        value: "Glencairn_Whiskey"
    },
    {
        label: "Goblet",
        value: "Goblet"
    },
    {
        label: "Highball",
        value: "Highball"
    },
    {
        label: "Large Growler",
        value: "Large_Growler"
    },
    {
        label: "Lowball",
        value: "Lowball"
    },
    {
        label: "Margarita",
        value: "Margarita"
    },
    {
        label: "Martini",
        value: "Martini"
    },
    {
        label: "Mug",
        value: "Mug"
    },
    {
        label: "Nonic Pint",
        value: "Nonic_Pint"
    },
    {
        label: "Pack",
        value: "Pack"
    },
    {
        label: "Pilsner",
        value: "Pitcher"
    },
    {
        label: "Pitcher",
        value: "Pitcher"
    },
    {
        label: "Sam Adams Pint",
        value: "Sam_Adams_Pint"
    },
    {
        label: "Sampler",
        value: "Sampler"
    },
    {
        label: "Shaker Pint",
        value: "Shaker_Pint"
    },
    {
        label: "Small Growler",
        value: "Small_Growler"
    },
    {
        label: "Snifter",
        value: "Snifter"
    },
    {
        label: "Spiegelau IPA",
        value: "Spiegelau_IPA"
    },
    {
        label: "Spiegelau Stout",
        value: "Spiegelau_Stout"
    },
    {
        label: "Spiegelau Wheat",
        value: "Spiegelau_Wheat"
    },
    {
        label: "Stange",
        value: "Stange"
    },
    {
        label: "Stemless Wine",
        value: "Stemless_Wine"
    },
    {
        label: "Teku",
        value: "Teku"
    },
    {
        label: "Tulip Pint",
        value: "Tulip_Pint"
    },
    {
        label: "Thistle",
        value: "Thistle"
    },
    {
        label: "Tulip",
        value: "Tulip"
    },
    {
        label: "Tumbler",
        value: "Tumbler"
    },
    {
        label: "Weizen",
        value: "Weizen"
    },
    {
        label: "Willi",
        value: "Willi"
    },
    {
        label: "Wine",
        value: "Wine"
    },
    {
        label: "Yardtube",
        value: "Yardtube"
    }
]

export const availabilityOptions = [
    {
        label: "Year Round",
        value: "Year_Round"
    },
    {
        label: "Seasonal",
        value: "Seasonal"
    },
    {
        label: "Limited",
        value: "Limited"
    },
    {
        label: "Archived",
        value: "Archived"
    }
]

export const productLifeTrackingOptions = [
    {
        label: "Expiration Date",
        value: "Expiration_Date"
    },
    {
        label: "Born on Date",
        value: "Born_on_Date"
    },
    {
        label: "No Tracking",
        value: "No_Tracking"
    }
]

export const couplerTypes = [
    {
        label: "D|American Sankey",
        value: "D"
    },
    {
        label: "S|European Sankey",
        value: "S"
    },
    {
        label: "G|Grundy-type",
        value: "G"
    },
    {
        label: "M|German Slider",
        value: "M"
    },
    {
        label: "A|German Slider",
        value: "A"
    },
    {
        label: "U|System",
        value: "U"
    }
]
export const servingSizes = [
    {
        label: "2oz",
        value: "_2"
    },
    {
        label: "4oz",
        value: "_4"
    },
    {
        label: "5oz",
        value: "_5"
    },
    {
        label: "8oz",
        value: "_8"
    },
    {
        label: "10oz",
        value: "_10"
    },
    {
        label: "12oz",
        value: "_12"
    },
    {
        label: "16oz",
        value: "_16"
    },
    {
        label: "20oz",
        value: "_20"
    },
    {
        label: "24oz",
        value: "_24"
    },
    {
        label: "32oz",
        value: "_32"
    },
    {
        label: "64oz",
        value: "_64"
    }
]
export const DAY_OF_WEEK = {
    // Sunday is 7 due to a setting with the Luxon library
    7: "Sun",
    1: "Mon",
    2: "Tue",
    3: "Wed",
    4: "Thu",
    5: "Fri",
    6: "Sat"
}

export const defaultServingSizesArr = [2, 4, 5, 8, 10, 12, 16, 20, 24, 32, 64]

export const BatteryReplacementPlan = {
    "90d": "_90_Days",
    "6m": "_6_Month",
    "1y": "_1_Year"
}

export const BatteryReplacmentOptionsData = [
    {
        value: BatteryReplacementPlan["90d"],
        label: "90 Day Supply"
    },
    {
        value: BatteryReplacementPlan["6m"],
        label: "6 Month Supply"
    },
    {
        value: BatteryReplacementPlan["1y"],
        label: "1 Year Supply"
    }
]
