"use client"
import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"

type Data = {
    tables: {
        [key: string]: {
            columns: string[]
        }
    }
}

type Actions = {
    toggleColumn: (table: string, column: string) => void
    setTableColumns: (table: string, columns: string[]) => void
}

export const useTableConfigStore = create<Data & Actions>()(
    devtools(
        persist(
            (set, get) => ({
                tables: {},
                toggleColumn: (table, column) => {
                    set((state) => {
                        const columns = state.tables[table]?.columns || []
                        const newColumns = columns.includes(column)
                            ? columns.filter((c) => c !== column)
                            : [...columns, column]

                        return {
                            tables: {
                                ...state.tables,
                                [table]: {
                                    columns: newColumns
                                }
                            }
                        }
                    })
                },
                setTableColumns: (table, columns) => {
                    set((state) => ({
                        tables: {
                            ...state.tables,
                            [table]: {
                                columns
                            }
                        }
                    }))
                }
            }),
            {
                name: "table-config"
            }
        )
    )
)
