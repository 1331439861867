import { withinRange } from "../helpers"

/**
 * PaceTerm
 *
 * Generates readable pace label from number
 *
 * @param  pace
 */
export const paceTerm = (pace: number | undefined | null) => {
    if (pace === undefined || pace === null) return "Pending"
    let label = ""
    switch (true) {
        case pace < -10:
            label = "Slow"
            break
        case withinRange(pace, -10, 10):
            label = "On Pace"
            break
        case pace > 10:
            label = "Fast"
            break
        default:
            label = "..."
            break
    }
    return label
}
