"use client"
import React from "react"

interface WindowSize {
    width: number | undefined
    height: number | undefined
}

export const useWindowSize = (): WindowSize => {
    const [windowSize, setWindowSize] = React.useState<WindowSize>({
        width: undefined,
        height: undefined
    })

    React.useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            })
        }

        window.addEventListener("resize", handleResize)
        handleResize()

        return () => window.removeEventListener("resize", handleResize)
    }, [])

    return windowSize
}
