const guidGenerator = (): string => {
    const guidTemplate = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx"
    const unique = guidTemplate.replace(/[xy]/g, (char) => {
        const random: number = (Math.random() * 16) | 0
        const v: number = char == "x" ? random : (random & 0x3) | 0x8
        return v.toString(16)
    })
    return unique
}
export default guidGenerator
