import _ from "lodash"
import { DateTime } from "luxon"
import { type Organization, OrganizationFields, SortDirection } from "types"

export const sortOrgsByField = (
    data: Organization[],
    dir: SortDirection,
    field: OrganizationFields
) => {
    const arr: Organization[] = _.cloneDeep(data)
    switch (field) {
        case OrganizationFields.ID: {
            arr.sort((a, b) =>
                dir === SortDirection.Desc
                    ? a.id?.localeCompare(b?.id)
                    : b?.id?.localeCompare(a?.id)
            )
            break
        }
        case OrganizationFields.ExternalID: {
            arr.sort((a, b) =>
                dir === SortDirection.Desc
                    ? a?.externalId?.localeCompare(b?.externalId)
                    : b.externalId?.localeCompare(a?.externalId)
            )
            break
        }
        case OrganizationFields.HubspotId: {
            arr.sort((a, b) =>
                dir === SortDirection.Desc
                    ? a?.hubspotId?.localeCompare(b?.hubspotId)
                    : b.hubspotId?.localeCompare(a?.hubspotId)
            )
            break
        }
        case OrganizationFields.Title: {
            arr.sort((a, b) =>
                dir === SortDirection.Desc
                    ? a?.title?.localeCompare(b?.title)
                    : b.title?.localeCompare(a?.title)
            )
            break
        }
        case OrganizationFields.Status: {
            arr.sort((a, b) =>
                dir === SortDirection.Desc
                    ? a.status?.localeCompare(b?.status)
                    : b.status?.localeCompare(a?.status)
            )
            break
        }
        case OrganizationFields.Brands: {
            arr.sort((a, b) => {
                const tenantA = a?.brands?.[0]?.title || "z"
                const tenantB = b?.brands?.[0]?.title || "z"
                return dir === SortDirection.Desc
                    ? tenantA.localeCompare(tenantB)
                    : tenantB.localeCompare(tenantA)
            })
            break
        }
        case OrganizationFields.BMSubscriptions: {
            arr.sort((a, b) => {
                const tenantA = a?.brewMenuSubscriptions?.[0]?.title || "z"
                const tenantB = b?.brewMenuSubscriptions?.[0]?.title || "z"
                return dir === SortDirection.Desc
                    ? tenantA.localeCompare(tenantB)
                    : tenantB.localeCompare(tenantA)
            })
            break
        }
        case OrganizationFields.PPSubscriptions: {
            arr.sort((a, b) => {
                const tenantA = a?.ppSubscriptions?.[0]?.title || "z"
                const tenantB = b?.ppSubscriptions?.[0]?.title || "z"
                return dir === SortDirection.Desc
                    ? tenantA.localeCompare(tenantB)
                    : tenantB.localeCompare(tenantA)
            })
            break
        }
        case OrganizationFields.Owner: {
            arr.sort((a, b) =>
                dir === SortDirection.Desc
                    ? a.owner?.contact?.email?.localeCompare(b?.owner?.contact?.email)
                    : b.owner?.contact?.email?.localeCompare(a?.owner?.contact?.email)
            )
            break
        }
        case OrganizationFields.Users: {
            arr.sort((a, b) =>
                dir === SortDirection.Desc
                    ? a?.users?.length - b?.users?.length
                    : b.users?.length - a?.users?.length
            )
            break
        }
        case OrganizationFields.City: {
            arr.sort((a, b) => {
                return dir === SortDirection.Desc
                    ? a?.address?.city?.localeCompare(b?.address?.city)
                    : b?.address?.city?.localeCompare(a?.address?.city)
            })
            break
        }
        case OrganizationFields.State: {
            arr.sort((a, b) => {
                return dir === SortDirection.Desc
                    ? a?.address?.state?.localeCompare(b?.address?.state)
                    : b?.address?.state?.localeCompare(a?.address?.state)
            })
            break
        }
        case OrganizationFields.Slug: {
            arr.sort((a, b) =>
                dir === SortDirection.Desc
                    ? a?.slug?.localeCompare(b?.slug)
                    : b?.slug?.localeCompare(a?.slug)
            )
            break
        }
        case OrganizationFields.UpdatedBy: {
            arr.sort((a, b) =>
                dir === SortDirection.Desc
                    ? a?.lastUpdatedBy?.localeCompare(b?.lastUpdatedBy)
                    : b?.lastUpdatedBy?.localeCompare(a?.lastUpdatedBy)
            )
            break
        }
        case OrganizationFields.UpdatedBy: {
            arr.sort((a, b) =>
                dir === SortDirection.Desc
                    ? a?.lastUpdatedBy?.localeCompare(b?.lastUpdatedBy)
                    : b.lastUpdatedBy?.localeCompare(a?.lastUpdatedBy)
            )
            break
        }
        case OrganizationFields.Updated: {
            arr.sort((a, b) => {
                const aDate = a?.updatedDate
                    ? DateTime.fromISO(a.updatedDate).toMillis()
                    : Number.MAX_VALUE
                const bDate = b?.updatedDate
                    ? DateTime.fromISO(b.updatedDate).toMillis()
                    : Number.MAX_VALUE
                return dir === SortDirection.Desc ? aDate - bDate : bDate - aDate
            })
            break
        }
        case OrganizationFields.CreatedBy: {
            arr.sort((a, b) =>
                dir === SortDirection.Desc
                    ? a?.createdBy?.localeCompare(b?.createdBy)
                    : b.createdBy?.localeCompare(a?.createdBy)
            )
            break
        }
        case OrganizationFields.Created: {
            arr.sort((a, b) => {
                const aDate = a?.createdDate
                    ? DateTime.fromISO(a.createdDate).toMillis()
                    : Number.MAX_VALUE
                const bDate = b?.createdDate
                    ? DateTime.fromISO(b.createdDate).toMillis()
                    : Number.MAX_VALUE
                return dir === SortDirection.Desc ? aDate - bDate : bDate - aDate
            })
            break
        }
        default:
            break
    }
    return arr
}
