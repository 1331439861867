"use client"
import { useEffect, useRef } from "react"

/**
 * Hook used to determine if a useEffect is running on mount vs running because of a change to dependencies.
 * Returns `True` if initial render
 * Returns `False` if render is triggered by a useEffect dependency
 */
const useIsInitialMount = (): boolean => {
    const isMountRef = useRef(true)
    useEffect(() => {
        isMountRef.current = false
    }, [])
    return isMountRef.current
}

export default useIsInitialMount
