import type { CompositeProduct } from "types"
import { parseEnumLabel } from "../string"

export const getProductStyle = (product: CompositeProduct): string => {
    let productStyle
    if (product?.style?.title) productStyle = product?.style?.title
    if (product?.ciderFamily) productStyle = parseEnumLabel(product?.ciderFamily)
    if (product?.kombuchaFamily || product?.__typename === "Kombucha")
        productStyle = product?.kombuchaFamily || product?.__typename

    return productStyle
}
